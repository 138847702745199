
<template>
  <base-layout-two page-title="Edit Inventory Count" :page-default-back-link="`/inventories/${$route.params.id}`">
    <div class="padding-8">
      <v-form @submit="onSubmit">
        <base-card title="Inventory Count Details">
          <ion-grid>
            <ion-row>
              <!-- Type -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Type *">
                  <v-field name="type" v-slot="{ field }" v-model="inventory_count.type" :rules="required">
                    <select v-bind="field" class="regular-select">
                      <option value="balance">Balance</option>
                      <option value="increment">Increment</option>
                    </select>
                  </v-field>
                </base-input>
                <v-error-message name="type" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Count -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Count *">
                  <v-field name="count" v-slot="{ field }" v-model="inventory_count.count" :rules="required">
                    <ion-input name="count" v-bind="field" type="number"></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="count" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- Date -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Date *">
                  <v-field name="date" v-slot="{ field }" v-model="inventory_count.date" :rules="required">
                    <ion-input name="date" v-bind="field" type="date"></ion-input>
                  </v-field>
                </base-input>
                <v-error-message name="date" class="error-message">
                </v-error-message>
              </ion-col>

              <!-- General Notes -->
              <ion-col size="12" size-lg="6">
                <base-input label-text="Notes">
                  <v-field name="general_notes" v-slot="{ field }" v-model="inventory_count.general_notes">
                    <ion-textarea name="general_notes" :auto-grow="true" rows="1" v-bind="field" placeholder=""
                      autocapitalize autocorrect>
                    </ion-textarea>
                  </v-field>
                </base-input>
                <v-error-message name="general_notes" class="error-message">
                </v-error-message>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-button type="submit" expand="block" class="margin-y-16">
            Save
          </ion-button>
        </base-card>
      </v-form>
    </div>
  </base-layout-two>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonInput, IonTextarea, IonButton } from "@ionic/vue";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { mapActions } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
    IonTextarea,
    IonButton,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      inventory_count: {},
      required: yup.string().required(),
    };
  },

  async ionViewWillEnter() {
    await this.fetchInventoryCount();
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),

    async fetchInventoryCount() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/inventory_counts/${this.$route.params.inventory_count_id}`)
        .then((response) => {
          this.inventory_count = response.data.inventory_count;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async onSubmit() {
      const config = {
        method: "patch",
        url: `${process.env.VUE_APP_API}/inventory_counts/${this.$route.params.id}`,
        data: this.inventory_count,
      };

      // Show a network loading indicator
      this.setShowLoading(true);

      await this.axios(config)
        .then((response) => {
          this.inventory_count = response.data.inventory_count;

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.$router.replace(`/inventories/${this.$route.params.id}/inventory_counts/${this.$route.params.inventory_count_id}`);
        })
        .catch((error) => {
          console.log(error);

          // Turn off the network loading indicator and prompt user with a toast
          this.setShowLoading(false);
          this.loadToast({ message: "Something went wrong", color: "danger" });
        });
    },
  },
};
</script>